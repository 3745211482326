
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    conf: {
      type: Object as PropType<EntryColMediaConfig>,
      required: true,
    },
  },
});
