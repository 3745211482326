
import { computed, defineComponent, watch, ref, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';

import EntryText from '../../components/Frontend/Entry/EntryText.vue';
import EntryMedia from '../../components/Frontend/Entry/EntryMedia.vue';
import EntrySlider from '../../components/Frontend/Entry/EntrySlider.vue';

import SocialMediaIconBox from '../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../components/Frontend/Footer.vue';

export default defineComponent({
  components: {
    EntryText,
    EntryMedia,
    EntrySlider,
    SocialMediaIconBox,
    Footer,
  },
  setup() {
    const entry = ref<i18nEntry>();
    const store = useStore();
    const router = useRouter();
    const i18n = useI18n();
    const id = computed(() => {
      if (router.currentRoute.value.params.id) {
        return (router.currentRoute.value.params.id as string).split('-')[0];
      } else {
        return 0;
      }
    });
    const injectMeta = inject('insertMeta') as Function;

    const publishdate = computed(() => {
      if (entry.value && entry.value.publishdate !== null) {
        return dayjs(entry.value.publishdate)
          .locale(i18n.locale.value)
          .format('DD. MMMM YYYY HH:MM');
      } else {
        return '';
      }
    });

    const fetchEntry = () => {
      store.dispatch('fetchEntry', id.value).then((res: i18nEntry) => {
        entry.value = res;
      });
      // .catch(() => {
      //   router.push({ name: '404' });
      // });
    };

    const localentry = computed(() => {
      if (entry.value && entry.value[i18n.locale.value as 'de' | 'en']) {
        return entry.value[i18n.locale.value as 'de' | 'en'];
      }
      return undefined;
    });

    const insertMeta = () => {
      const meta: { [propType: string]: any } = {};
      meta[i18n.locale.value] = {
        title: localentry.value?.title,
        description: localentry.value?.introtext,
        image: `${store.getters.getAPIBase}media/${entry.value?.thumbnail}`,
        keywords: localentry.value?.keywords.split(','),
      };
      injectMeta(meta, i18n.locale.value);
    };

    if (id.value != undefined) {
      fetchEntry();
    }
    // eslint-disable-next-line
    watch(id, (newid) => {
      if (newid != undefined) {
        fetchEntry();
      }
    });

    // eslint-disable-next-line
    watch(localentry, (newentry) => {
      if (newentry == undefined) {
        router.push({ name: '404' });
      } else {
        insertMeta();
      }
    });

    return {
      entry,
      i18n,
      localentry,
      publishdate,
    };
  },
});
