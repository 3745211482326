<template>
  <div class="entryslider-container" :class="{ hasDescription: conf.description }">
    <Slider :items="1" :autoplay="conf.autoplay">
      <template #navigation="{left, right}" v-if="conf.arrows">
        <div class="arrow-navi">
          <div class="arrow-btn arrow-left" @click="left">
            <img src="@/assets/img/slider-arrow-right-bg-white.svg" alt="Pfeil Rechts" class="img-fluid" />
          </div>
          <div class="arrow-btn arrow-right" @click="right">
            <img src="@/assets/img/slider-arrow-left-bg-white.svg" alt="Pfeil Links" class="img-fluid" />
          </div>
        </div>
      </template>
      <template #items>
        <SliderItem :key="index" v-for="index in conf.ids">
          <Media :media="index" :scaling="conf.scaling" :height="conf.height" width="100%" :position="conf.position" />
        </SliderItem>
      </template>
    </Slider>

    <div class="entryslider-container-text" v-if="conf.description">
      {{ conf.description }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Slider from '../../Slider.vue';
import SliderItem from '../../SliderItem.vue';

export default defineComponent({
  components: {
    Slider,
    SliderItem,
  },
  props: {
    conf: {
      type: Object as PropType<EntryColSliderConfig>,
      required: true,
    },
  },
});
</script>
