<template>
  <p v-html="conf.text"></p>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    conf: {
      type: Object as PropType<EntryColTextConfig>,
      required: true,
    },
  },
});
</script>
