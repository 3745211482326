<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .news-all {
    .col-lg-4 {
      align-self: center;
      .news-item {
        margin-bottom: 40px;
      }
    }
  }
}
</style>

<template>
  <div class="news-all">
    <SocialMediaIconBox />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb"><span>Marketplaces</span></div>
              <h1>News</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center align-self-center">
        <EntryIndex category="3" routename="marketplacesnewsdetail" showerror paged />
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';
import EntryIndex from '../../../components/Frontend/EntryIndex.vue';

export default defineComponent({
  meta: {
    de: {
      title: 'News | Styria Media Group',
      keywords: ['news', 'newsroom', 'neuigkeiten', 'brands', 'styria', 'styria media group'],
      description: 'Was gibt es Neues bei unseren Brands?',
      image: require('@/assets/img/og/service.png'),
    },
    en: {
      title: 'News | Styria Media Group',
      keywords: ['news', 'newsroom', 'articles', 'brands', 'styria', 'styria media group'],
      description: 'All the latest news from our brands at a glance.',
      image: require('@/assets/img/og/service.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Footer,
    EntryIndex,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
