<style lang="scss">
.entryheader {
  .header {
    text-align: center;
    padding: 50px 0;

    .breadcrumb {
      display: block;
      text-align: center;
      margin-top: 30px;
      letter-spacing: $letter-spacing-std;
    }
    h1 {
      max-width:60%;
      margin:30px auto 0 auto;
      font-size:2.8rem;
    
      @media (max-width: $width-lg) {
        padding: 0 20px;
        max-width:90%;
        font-size:1.6rem;
        br {
          display:none;
        }
      }
      @media (max-width: $width-sm) {
        padding: 0px;
        
      }
    }
    .date {
      font-size: 80%;
      letter-spacing: 1px;
      margin-top: 30px;
    }
    .thumbnail {
      margin-top:40px;
      @media(max-width:$width-lg) {
        margin-top:0px;
      }
    }
    .thumbnail-description {
      font-size:90%;
      font-family: 'Helvetica Neue LT W05 56 Italic';
      letter-spacing: 1px;
      padding: 8px 5px;
    }
    .news-intro-txt {
      margin-top: 60px;
      line-height: 1.8;
      letter-spacing: 1px;
      font-size: 1.2rem;
      text-align:left;
      padding:0 20px;
      border-left:5px solid $coral;

      font-family: 'Helvetica Neue LT W05 65 Medium';
      font-family: 'Helvetica Neue LT W05 56 Italic';
    }
    .author-outer-box {
      [class*='col-'] {
        //border:1px solid red;
      }
      margin-top:30px;
      margin-bottom:30px;
      .author {
        
        text-align: center;
        letter-spacing: 2px;
        padding-top: 10px;
      
      }
      .authorimage {
        border: 0px solid red;
        max-width: 80px;
        display: block;
        margin: 15px auto;
      }
    }
  }
}
.entrycontent {
  padding-top: 50px;
  .mediacontainer {
    padding: 20px 0;
  }
  .mediacontainer.hasdescription {
  }
  .slidercontainer {
    //border:1px solid red;
    cursor: pointer;
  }
  p {
    a {
      //border:1px solid blue;
      border-bottom: 1px solid #000;
      &:hover {
        color: #000;
        opacity: 0.7;
      }
    }
  }
}
.entryfooter {
  text-align: center;
  padding-top: 50px;
  .keywords {
    font-size: 90%;
    padding: 0 50px;
    @media (max-width: $width-md) {
      padding: 0 20px;
    }
    span {
      margin-right: 8px;
      letter-spacing: $letter-spacing-std;
      a {
        color: $c-grey;
        display: inline-block;
        &:hover {
          color: #000;
        }
      }
    }
  }
  
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="container entryheader">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="header">
          <!--<div class="breadcrumb"><span>Newsroom</span></div>-->
          <div class="date">{{ publishdate }}</div>
          <h1 v-html="localentry?.title"></h1>
           <div class="row author-outer-box justify-content-center">
            <div class="col-lg-10 align-self-center ">
              <div class="row">
                  <div class="col-lg-12 align-self-center" v-if="localentry?.authorpicture">
                    <div class="authorimage" >
                      <Media :media="localentry?.authorpicture" />
                    </div>
                  <div class="author" v-if="localentry?.author">
                    {{ localentry?.author }}
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
          <div class="row justify-content-center thumbnail" v-if="entry?.thumbnail">
            <div class="col-lg-12 col-md-10">
              <Media :media="entry.thumbnail"/>
              <div class="thumbnail-description" v-if="localentry?.thumbnaildescription">
              {{ localentry?.thumbnaildescription }}
              </div>              
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
              <div class="news-intro-txt">{{ localentry?.introtext }}</div>
            </div>
          </div>
         
          
          
        </div>
      </div>
    </div>
  </div>
  <div class="container entrycontent" v-if="localentry?.rows">
    <div class="row justify-content-center" :key="row.id" v-for="row in localentry.rows">
      <div :class="`col-lg-${col.width}`" :key="col" v-for="col in row.cols" class="align-self-center col-11">
        <EntryText :conf="col.typeconf" v-if="col.type == 'text'" />
        <EntryMedia :conf="col.typeconf" v-if="col.type == 'media'" />
        <EntrySlider :conf="col.typeconf" v-if="col.type == 'slider'" />
      </div>
    </div>
  </div>
  <div class="container entryfooter">
    <div class="col-12 keywords">
      <span :key="keyword" v-for="keyword in localentry?.keywords.split(',')">
        <router-link :to="{ name: 'newskeyword', params: { keyword: keyword } }" v-if="keyword">#{{ keyword }}</router-link>
      </span>
    </div>
    
  </div>
  <Footer />
</template>

<script lang="ts">
import { computed, defineComponent, watch, ref, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';

import EntryText from '../../components/Frontend/Entry/EntryText.vue';
import EntryMedia from '../../components/Frontend/Entry/EntryMedia.vue';
import EntrySlider from '../../components/Frontend/Entry/EntrySlider.vue';

import SocialMediaIconBox from '../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../components/Frontend/Footer.vue';

export default defineComponent({
  components: {
    EntryText,
    EntryMedia,
    EntrySlider,
    SocialMediaIconBox,
    Footer,
  },
  setup() {
    const entry = ref<i18nEntry>();
    const store = useStore();
    const router = useRouter();
    const i18n = useI18n();
    const id = computed(() => {
      if (router.currentRoute.value.params.id) {
        return (router.currentRoute.value.params.id as string).split('-')[0];
      } else {
        return 0;
      }
    });
    const injectMeta = inject('insertMeta') as Function;

    const publishdate = computed(() => {
      if (entry.value && entry.value.publishdate !== null) {
        return dayjs(entry.value.publishdate)
          .locale(i18n.locale.value)
          .format('DD. MMMM YYYY HH:MM');
      } else {
        return '';
      }
    });

    const fetchEntry = () => {
      store.dispatch('fetchEntry', id.value).then((res: i18nEntry) => {
        entry.value = res;
      });
      // .catch(() => {
      //   router.push({ name: '404' });
      // });
    };

    const localentry = computed(() => {
      if (entry.value && entry.value[i18n.locale.value as 'de' | 'en']) {
        return entry.value[i18n.locale.value as 'de' | 'en'];
      }
      return undefined;
    });

    const insertMeta = () => {
      const meta: { [propType: string]: any } = {};
      meta[i18n.locale.value] = {
        title: localentry.value?.title,
        description: localentry.value?.introtext,
        image: `${store.getters.getAPIBase}media/${entry.value?.thumbnail}`,
        keywords: localentry.value?.keywords.split(','),
      };
      injectMeta(meta, i18n.locale.value);
    };

    if (id.value != undefined) {
      fetchEntry();
    }
    // eslint-disable-next-line
    watch(id, (newid) => {
      if (newid != undefined) {
        fetchEntry();
      }
    });

    // eslint-disable-next-line
    watch(localentry, (newentry) => {
      if (newentry == undefined) {
        router.push({ name: '404' });
      } else {
        insertMeta();
      }
    });

    return {
      entry,
      i18n,
      localentry,
      publishdate,
    };
  },
});
</script>
