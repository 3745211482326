<template>
  <Media :media="conf.id" :width="conf.width" :height="conf.height" :scaling="conf.scaling" :position="conf.position" :description="conf.description" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    conf: {
      type: Object as PropType<EntryColMediaConfig>,
      required: true,
    },
  },
});
</script>
