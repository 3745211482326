
import { defineComponent, PropType } from 'vue';
import Slider from '../../Slider.vue';
import SliderItem from '../../SliderItem.vue';

export default defineComponent({
  components: {
    Slider,
    SliderItem,
  },
  props: {
    conf: {
      type: Object as PropType<EntryColSliderConfig>,
      required: true,
    },
  },
});
