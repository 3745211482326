
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';
import EntryIndex from '../../../components/Frontend/EntryIndex.vue';

export default defineComponent({
  meta: {
    de: {
      title: 'News | Styria Media Group',
      keywords: ['news', 'newsroom', 'neuigkeiten', 'brands', 'styria', 'styria media group'],
      description: 'Was gibt es Neues bei unseren Brands?',
      image: require('@/assets/img/og/service.png'),
    },
    en: {
      title: 'News | Styria Media Group',
      keywords: ['news', 'newsroom', 'articles', 'brands', 'styria', 'styria media group'],
      description: 'All the latest news from our brands at a glance.',
      image: require('@/assets/img/og/service.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Footer,
    EntryIndex,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
